/* Dropdowns */

.dropdown {
  .dropdown-menu {
    margin-top: .75rem;
    font-size: $default-font-size;
    box-shadow: $dropdown-menu-box-shadow;
    .dropdown-item {
      font-size: 1rem;
      padding: .25rem 1.5rem;
      &:active {
        background: initial;
      }
    }
  }
}
