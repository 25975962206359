.c3-axis,
.c3-legend-item {
  fill: rgba(255, 255, 255, 0.7);
}
.c3 path,
.c3 line {
  stroke: #2e2f32;
}
.c3-chart-arcs-title {
  fill: rgba(255, 255, 255, 0.7);
}
