/* Layouts */

.navbar {
  &.fixed-top {
    + .page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      left: $sidebar-width-icon;
      .navbar-menu-wrapper {
        width: 100%;
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          padding: 0;
          .profile-name {
            display: none;
          }
          .mdi-dots-vertical {
            display: none;
          }
          &.account-dropdown {
            display: none;
          }
          .nav-link {
            text-align: center;
            .menu-title,
            .badge,.menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title{
                @include border-radius(0 5px 0 0px);
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }
          &.nav-profile {
            display: none;
          }
          &.nav-category {
            display: none;
          }
          &.nav-doc {
            margin: 0;
            i {
              display: block;
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $icon-only-menu-bg-light;
                @at-root #{selector-append(".sidebar-dark", &)} {
                    background: $sidebar-dark-menu-active-bg;
                }
                padding: 0.5rem 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                }
                &:after {
                  display: none;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: .5rem 0;
              background: $icon-only-menu-bg-light;
              @include border-radius(0 0 5px 0);
              @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-dark-bg;
              }
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right:$sidebar-width-icon;
                  @include border-radius(0 0 0 5px);
              }
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              padding-left: 20px;
              &:before {
                content: "";
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $sidebar-dark-submenu-color;
                position: absolute;
                left: 0;
            }
            &:hover {
              &:before {
                background-color: white;
              }
            }
            }
          }
          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-left: 0;
              .nav-link {
                text-align: right;
                &:before{
                  left: 0;
                  right: unset;
                }
              }
            }
          }
        }
      }
      .sidebar-actions {
        display: none;
      }
    }
    &.rtl {
      .sidebar {
        .nav {
          .nav-item {
            &.profile {
              padding-right: 0;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      width: calc(100% - #{$sidebar-width-icon});
      transition: all $action-transition-duration $action-transition-timing-function;
      -webkit-transition: all $action-transition-duration $action-transition-timing-function;
      -moz-transition: all $action-transition-duration $action-transition-timing-function;
      -ms-transition: all $action-transition-duration $action-transition-timing-function;
    }
  }
}
