/* Fonts */

//PT_Sans
@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  src: url('../fonts/Rubik/Rubik-Light.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url('../fonts/Rubik/Rubik-Regular.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  src: url('../fonts/Rubik/Rubik-Medium.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  src: url('../fonts/Rubik/Rubik-Bold.ttf'); /* IE9 Compat Modes */
}
