/* Badges */

.badge {
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: initial;
  line-height: 1;
  padding: 0.5rem 0.7rem;
  font-weight: $font-weight-medium;
  &.badge-pill {
    border-radius: 10rem;
  }
}


/*Badge variations*/
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variations($value);
  }
}

/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}